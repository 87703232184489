<template>
  <div>
    <ds-header title="返还比设置"></ds-header>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item ref="sceneRuleType" label="积分类型" prop="sceneRuleType">
        <a-radio-group :disabled="disabled" v-model="form.sceneRuleType">
          <a-radio :value="0">
            消费积分
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item ref="sceneRuleName" label="规则名称" prop="sceneRuleName">
        <a-input :disabled="disabled" :maxLength="20" v-model.trim="form.sceneRuleName"/>
      </a-form-model-item>
      <a-form-model-item ref="ruleDate" label="店铺消费返还比" :required="true">
<!--        <a-button type="primary" icon="upload" ghost>上传文件</a-button>-->
        <span v-if="!disabled">
            <a-upload
              :disabled="disabled"
              action="/api/dscloud-member-points/web/scene-rule/find-merchant-list"
              name="file"
              :multiple="true"
              accept=".xlsx"
              :showUploadList="false"
              @change="handleChangeFile"
            >
              <a-button type="primary"> <a-icon type="upload" /> 上传文件 </a-button>
              <a-button type="link" style="margin-left: 15px" @click="downloadTemplate">下载模板</a-button>
            </a-upload>
          </span>
        <div v-if="!disabled">仅支持扩展名：xlsx</div>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 18, offset: 4 }" v-show="merchantList.length">
        <a-table :pagination="false" :rowKey="(record, index) => { return index }" :columns="columns" :data-source="merchantList" bordered>
<!--          ['merchantId', 'merchantName', 'validStartTime', 'validEndTime', 'payAmount', 'integralAmount']"-->
<!--          商户ID-->
          <template slot="merchantId" slot-scope="text, record, index">
            <div>
              <a-input
                v-if="record.editable"
                style="margin: -5px 0"
                :value="text"
                :maxLength="30"
                @change="e => handleChange(e.target.value, record.merchantId, 'merchantId')"
              />
              <template v-else>
                {{ text }}
              </template>
            </div>
          </template>
<!--          商户名称-->
          <template slot="merchantName" slot-scope="text, record, index">
            <div>
              <a-input
                v-if="record.editable"
                style="margin: -5px 0"
                :value="text"
                :maxLength="20"
                @change="e => handleChange(e.target.value, record.merchantId, 'merchantName')"
              />
              <template v-else>
                {{ text }}
              </template>
            </div>
          </template>
<!--          有效开始时间-->
          <template slot="validStartTime" slot-scope="text, record, index">
            <div>
              <a-date-picker
                :allowClear="false"
                v-if="record.editable"
                style="margin: -5px 0"
                v-model="text"
                show-time
                placeholder="选择有效开始时间"
                @change="e => handleChange(moment(e).format('YYYY-MM-DD HH:mm:ss'), record.merchantId, 'validStartTime')"
              />
              <template v-else>
                {{ text }}
              </template>
            </div>
          </template>
<!--          有效结束时间-->
          <template slot="validEndTime" slot-scope="text, record, index">
            <div>
              <a-date-picker
                :allowClear="false"
                v-if="record.editable"
                style="margin: -5px 0"
                v-model="text"
                show-time
                placeholder="选择有效开始时间"
                @change="e => handleChange(moment(e).format('YYYY-MM-DD HH:mm:ss'), record.merchantId, 'validEndTime')"
              />
              <template v-else>
                {{ text }}
              </template>
            </div>
          </template>
<!--          金额值-->
          <template slot="payAmount" slot-scope="text, record, index">
            <div>
              <a-input-number
                v-if="record.editable"
                style="margin: -5px 0"
                :value="text"
                :max="99999"
                :min="0"
                :precision="2"
                @change="e => handleChange(e, record.merchantId, 'payAmount')"
              />
              <template v-else>
                {{ text }}
              </template>
            </div>
          </template>

<!--          积分比例值-->
          <template slot="integralAmount" slot-scope="text, record, index">
            <div>
              <a-input-number
                v-if="record.editable"
                style="margin: -5px 0"
                :value="text"
                :max="9999"
                :min="0"
                :precision="0"
                @change="e => handleChange(e, record.merchantId, 'integralAmount')"
              />
              <template v-else>
                {{ text }}
              </template>
            </div>
          </template>

          <template slot="operation" slot-scope="text, record, index">
            <div class="editable-row-operations">
              <span v-if="record.editable">
                <a @click="() => save(record.merchantId)" style="margin-right: 10px">保存</a>
                <a-popconfirm title="确定要取消吗?" @confirm="() => cancel(record.merchantId)">
                  <a>取消</a>
                </a-popconfirm>
              </span>
              <span v-else>
                <a :disabled="editingKey !== '' || disabled" @click="() => edit(record.merchantId)">编辑</a>
              </span>
            </div>
          </template>
        </a-table>
      </a-form-model-item>
      <a-form-model-item ref="sceneRuleShowHint" label="用户积分明细提示" prop="sceneRuleShowHint">
        <a-input :disabled="disabled" :maxLength="10" v-model.trim="form.sceneRuleShowHint"/>
      </a-form-model-item>
      <a-form-model-item ref="restrictType" label="用户身份限制">
        <a-radio-group :disabled="disabled" v-model="form.restrictType">
          <a-radio :value="0">
            无限制
          </a-radio>
          <a-radio :value="1">
            企业认证
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item v-if="form.restrictType === 1" label="企业类型：" prop="enterpriseType" :span="24">
        <a-select
          :disabled='disabled'
          class="indexClass"
          placeholder="请选择"
          v-model="form.enterpriseType"
          mode="multiple"
        >
          <a-select-option v-for="item in enterpriseTypeList" :key="item.id" :value="item.id">
            {{ item.commonsValueName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item ref="projectIds" label="所属项目" prop="projectIds">
        <a-select
          :disabled="disabled"
          v-model="form.projectIds"
          mode="multiple"
          placeholder="请选择所属项目"
        >
          <a-select-option v-for="item in projectList" :key="item.id">
            {{ item.project_name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit" v-if="!disabled">
          提交
        </a-button>
        <a-button @click="cancelModel" style="margin-left: 10px;">
          取消
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { getIntegralReturnRatioById, saveIntegralReturnRatio, editIntegralReturnRatio } from '@/api/integralManagement'
import { projectName } from '@/api/modularManagement'
import XLSX from "xlsx";
import moment from 'moment'
const merchantList = []
import {IMG_API} from "@/config";
import {getEnterpriseTypes} from "@/api/common";
export default {
  data() {
    // this.cacheData = merchantList.map(item => ({ ...item }));
    return{
      IMG_API,
      labelCol: { span: 4 },
      wrapperCol: { span: 6 },
      projectList: [],
      cacheData:[],
      moment,
      disabled: false,
      columns: [
        {
          title: '商户ID',
          dataIndex: 'merchantId',
          scopedSlots: { customRender: 'merchantId' },
        },
        {
          title: '商户名称',
          dataIndex: 'merchantName',
          scopedSlots: { customRender: 'merchantName' },
        },
        {
          title: '消费金额（每笔）',
          dataIndex: 'payAmount',
          scopedSlots: { customRender: 'payAmount' },
        },
        {
          title: '获得积分值',
          dataIndex: 'integralAmount',
          scopedSlots: { customRender: 'integralAmount' },
        },
        {
          title: '有效开始时间',
          dataIndex: 'validStartTime',
          scopedSlots: { customRender: 'validStartTime' },
        },
        {
          title: '有效结束时间',
          dataIndex: 'validEndTime',
          scopedSlots: { customRender: 'validEndTime' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: '150px',
        },
      ],
      editingKey: '',
      merchantList,
      // 所有企业类型
      enterpriseTypeList: [],
      form: {
        sceneRuleType: 0,
        sceneRuleName: '',
        sceneRuleShowHint: '',
        restrictType: 0,
        enterpriseType:[],
        projectIds: [],
        merchantDTOList: [],
        merchantFileUrl: ''
      },
      rules: {
        sceneRuleType: [
          {required: true, message: '请选择积分类型', trigger: 'change'}
        ],
        sceneRuleName: [
          {required: true, message: '请输入规则名称', trigger: 'blur'}
        ],
        sceneRuleShowHint: [
          {required: true, message: '请输入积分明细提示', trigger: 'blur'}
        ],
        enterpriseType: [{ required: true, message: "请选择企业类型", trigger: "blur" }],
        projectIds: [
          {required: true, message: '请选择所属项目', trigger: 'change'}
        ],
      }
    }
  },
  async created() {
    // 获取所有项目列表
    await this.getProjectList()
    await this.getEnterpriseTypeList();
    const type = this.$route.query.type
    if (type) {
      if (type === 'view') {
        this.disabled = true
      }
      const id = this.$route.query.id
      await this.getDetail(id)
    }
  },
  methods: {
    // 获取所有项目
    async getProjectList() {
      const res = await projectName();
      const { code, data: { items} } = res
      if (code === '200') {
        this.projectList = items
      }
    },
    // 企业类型
    async getEnterpriseTypeList() {
      const res = await getEnterpriseTypes();
      const { code, data } = res
      if (code === '200') {
        this.enterpriseTypeList = data;
      }
    },
    // 详情
    async getDetail(id) {
      const params = {
        id: id
      }
      const res = await getIntegralReturnRatioById(params);
      const { code, data, msg } = res
      if (code === '200') {
        this.form = data
        this.form.enterpriseType = data.enterpriseType || []
        this.merchantList = data.merchantList
        this.cacheData = data.merchantList
      } else {
        this.$message.error(msg)
      }
    },
    onSubmit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          // 检查商户list
          if(!this.checkData(this.merchantList)) {
            return false
          }
          if (this.$route.query.type) {
            await this.toEdit()
          } else {
            await this.toSaveIntegralReturnRatio()
          }
        } else {
          return false;
        }
      });
    },
    // 新增
    async toSaveIntegralReturnRatio() {
      const res = await saveIntegralReturnRatio(this.getParams())
      const { msg, code } = res
      if (code === '200') {
        this.$message.success('操作成功！')
        this.cancelModel()
      } else {
        this.$message.error(msg)
      }
    },
    // 编辑
    async toEdit() {
      const res = await editIntegralReturnRatio(this.getParams())
      const { msg, code } = res
      if (code === '200') {
        this.$message.success('操作成功！')
        this.cancelModel()
      } else {
        this.$message.error(msg)
      }
    },
    // 保存参数
    getParams() {
      return {
        ...this.form,
        merchantDTOList: this.merchantList,
        enterpriseType: this.form.restrictType === 0 ? [] : this.form.enterpriseType
      }
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    // 下载模板
    downloadTemplate() {
      location.href = "https://oss-workplace-prod.innoecos.cn/picture/075eec3b7e400000_%E5%BA%97%E9%93%BA%E6%B6%88%E8%B4%B9%E8%BF%94%E8%BF%98%E6%AF%94.xlsx";
    },
    // 取消
    cancelModel() {
      this.$router.back()
    },
    // 上传excel并解析数据
    handleChangeFile({ file }) {

      console.log(file)
      // 上传文件
      if (file && file?.response) {
        const { code, data, msg } = file.response
        if (code === '200') {
          this.merchantList = data;
          this.cacheData = data
        } else {
          this.$message.error(msg)
        }

      }

      // const reader = new FileReader();
      // reader.onload = () => {
      //   const txt = reader.result;
      //   const wb = XLSX.read(txt, {
      //     type: "binary"
      //   });
      //   const json = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
      //   // 到json就可以获取到解析后的结果了
      //   const newData = json.map(item => {
      //     return {
      //       merchantId: item.商户id,
      //       merchantName: item.商户名称,
      //       validStartTime: item.开始时间,
      //       validEndTime: item.结束时间,
      //       integralAmount: item.积分比例值,
      //       payAmount: item.消费金额
      //     };
      //   });
      //   try {
      //     if (newData && newData.length === 0) {
      //       this.$message.error("excel没有读到数据!")
      //       throw new Error()
      //     }
      //     if (!this.checkData(newData)) {
      //       return;
      //     }
      //   } catch (err) {
      //     return false;
      //   }
      //   this.merchantList = newData;
      //   this.cacheData = newData
      //   console.log(newData)
      // };
      // if (file.status === "error") {
      //   reader.readAsBinaryString(file.originFileObj);
      // }
      // debugger
      // // 上传文件
      // if (file && file?.response) {
      //   this.form.merchantFileUrl = file.response.redirect_uri
      // }
    },
    checkData(data) {
      try{
        data.forEach(item => {
          if (!item.merchantId || !item.merchantName
            || !item.validStartTime || !item.validEndTime || !item.payAmount
            || !item.integralAmount) {
            this.$message.error("excel数据不正确！")
            throw new Error()
          }
        })
        data.forEach(item => {
          if (item.payAmount < 0) {
            this.$message.error(`消费金额必须大于0！`)
            throw new Error()
          }
          if (item.integralAmount <= 0) {
            this.$message.error(`获得积分值必须大于0！`)
            throw new Error()
          }
          if(item.validStartTime > item.validEndTime) {
            this.$message.error(`${item.merchantName}的开始时间不能大于结束时间！`)
            throw new Error()
          }
        })
        return true;
      } catch (e) {
        return false;
      }
    },
    handleChange(value, key, column) {
      const newData = [...this.merchantList];
      const target = newData.find(item => key === item.merchantId);
      if (target) {
        target[column] = value;
        this.merchantList = newData;
      }
    },
    edit(key) {
      const newData = [...this.merchantList];
      const target = newData.find(item => key === item.merchantId);
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.merchantList = newData;
      }
    },
    save(key) {
      const newData = [...this.merchantList];
      const newCacheData = [...this.cacheData];
      const target = newData.find(item => key === item.merchantId);
      const targetCache = newCacheData.find(item => key === item.merchantId);
      if (target && targetCache) {
        delete target.editable;
        this.merchantList = newData;
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
      }
      this.editingKey = '';
    },
    cancel(key) {
      const newData = [...this.merchantList];
      const target = newData.find(item => key === item.merchantId);
      this.editingKey = '';
      if (target) {
        Object.assign(target, this.cacheData.find(item => key === item.merchantId));
        delete target.editable;
        this.merchantList = newData;
      }
    },
  }
}
</script>
